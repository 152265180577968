import Base from "./Base";
export default class En extends Base {
    init() {
        this.lang = {
            account_number: "Accounts",
            password: "Password",
            sign_in: "Log In",
            class_list_nav: "Class Roll Call",
            roll_call: "Roll Call",
            submit: "Submit",
            exit_login: "Confirm to log out?",
            cancel: "Cancel",
            determine: "Determine",
            student: "student",
            state: "State",
            on_time: "Attendance",
            late: "Late",
            absence: "Absence",
            leave: "Leave",
            unjoin: "Joins Later",
            no_courses: "No Courses",
            remarks: "Remarks",
            comments: "student comments",
            userNull: 'Please enter your account number',
            passwordNull: "Please enter your password",
            accountNumberOrPasswordError: "account number or password error",
            telphoneError: "telphone error",
            createLesson: "create lesson",
            selectCourse: "select course",
            create: "Create",
            time: "Time",
            place: "Place",
            studentsCount: "students Count",
            classAttendance: "Class Attendance",
            late: "Late",
            noMore: "No More",
            selectDate: "select date",
            pushMe: "push me",
            lessonCreated: "The lesson has been created",
            createdLessonSuccess: "created lesson success",
            finishClassAndSubmit: "finish class and submit",
            Itcannotbemodifiedaftersubmission: "It cannot be modified after submission",
            yes: "Yes",
            no: "No",
            submitSuccess: "submit success",
            data_is_null: "data is null",
            after_class: "operation",
            class_evaluation: "Class Summary",
            personal_evaluation: "personal evaluation",
            upload_job: "upload homework",
            fill_in_class_rating: "Fill in the class evaluation...",
            publish: "publish",
            uploadVideo: "upload video",
            justUpload: "just upload image for jpg or png",
            justUploadVideo: "just upload video for mp4",
            pictureSize: "The picture size cannot be greater than 2M",
            videoSize: "The video size cannot be greater than 50M",
            select_file: "select file",
            jobSize: "The file size cannot be greater than 10M",
            justUploadjob: "just upload file for doc,docx or pdf",
            filenametoolong: "The file name is too long",
            DeleteThisJob: "delete this homework ?",
            JobUploaded: "honmework uploaded",
            uploading: "uploading...",
            timeout: "Upload timeout, please upload again",
            networkError: "Network error, please try again",
            weeks: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            weekstr: 'week',
            notfilledIn: "There are students who have not filled in the status",
            confirm: "Confirm",
            evaluateStudents: "Evaluate Students",
            uploadpdforword: "upload pdf or word file",
            DeleteVideo: "delete video！！！",
            isDeleteVideo: "Are you sure to delete the video ？",
            // 点名系统
            route_home: 'Attendance&Feedback',
            route_course: 'All Courses',
            route_my: 'My',
            route_accountInformation: 'Account Information',
            route_coursecheckin: 'Class check-in',
            route_comment: 'Teacher Ccomments',
            route_misslesson: 'Make-up students',
            status_course: 'Course Reminders',
            status_SignInReview: 'To-dos',
            status_reviewed: 'Done',
            type_All: "All",
            type_makeup: "To be signed",
            type_review: "To be commented",
            type_reviewed: "Done",
            typecourse_day: "Daily course",
            typecourse_week: "Weekly course",
            typecourse_month: "Monthly course",
            tab_personreview: "Teacher Comments",
            tab_class: "Class Reviews",
            tab_Homework: "Upload Homework",
            formLabel_courseProgress: "Course Progress",
            formLabel_student: "Number of students",
            formLabel_time: "Time",
            formLabel_place: "Venue",
            btn_review: "Review",
            btn_reviewed: "Reviewed",
            btn_signIn: "Sign in",
            btn_looksignIn: "Check-ins",
            btn_complementSigned: "Make up",
            btn_changeDate: "Change the date",
            btn_looksignIn: "Check-ins",
            accountInformation: 'Account information',
            loginOut: "Log out",
            Available: 'Remaining',
            loading: "loading...",
            search: "Search",
            search_placeholder: "Enter name/number",
            no_student: "No student’s information~",
            confirm_misslesson: 'Confirm to make up missed lessons?',
            confirm_comments: 'Summit the teacher’s comments？',
            confirm_work: 'Confirm homework assignment?',
            confirm_checkin: 'Confirm Attendance?',
            placeholder_student: "1、Course content\n2、Student personal performance",
            placeholder_class: "1、Course content\n2、Student personal performance",
            placeholder_work: "Fill in homework content...",
            submit_type_success: "Success",
            submit_type_fail: "Fail",
            addstudent: "Please add students",
            pleaseselect: "Please Select",
            cn_name: "Chinese Name",
            en_name: "English Name",
            pullingtext: "pull-to-refresh...",
            loosingtext: "Release to refresh...",
            save:"Save"
        };
    }
}