import Base from "./Base";
export default class Ch extends Base {
    init() {
        this.lang = {
            account_number: "账号",
            password: "密码",
            sign_in: "登 录",
            class_list_nav: "课堂点名",
            roll_call: "点名",
            submit: "提交",
            exit_login: "是否退出登录",
            cancel: "取消",
            determine: "确定",
            student: "学生",
            state: "状态",
            on_time: "出勤",
            late: "迟到",
            absence: "缺勤",
            leave: "请假",
            unjoin: "尚未加入",
            no_courses: "暂无课程",
            remarks: "评论",
            comments: "学生评论",
            userNull: "请输入你的账号",
            passwordNull: "密码没填写",
            accountNumberOrPasswordError: "账号或密码错误",
            telphoneError: "电话号码错误",
            createLesson: "创建课程",
            selectCourse: "选择课程",
            create: "创建",
            time: "时间",
            place: "地点",
            studentsCount: "学生数",
            classAttendance: "出勤情况",
            late: "迟到",
            noMore: "没有更多了",
            selectDate: "选择日期",
            pushMe: "点击选择",
            lessonCreated: "课程已被创建",
            createdLessonSuccess: "成功创建课程",
            finishClassAndSubmit: "下课并提交",
            Itcannotbemodifiedaftersubmission: "一旦提交数据后不能修改",
            yes: "是",
            no: "否",
            submitSuccess: "提交成功",
            date_is_null: "日期没填写",
            after_class: "操作",
            class_evaluation: "班级授课内容",
            personal_evaluation: "个人评价",
            upload_job: "上传作业",
            fill_in_class_rating: "填写班级评价...",
            publish: "发表",
            uploadVideo: "上传视频",
            justUpload: "只能上传jpg或者png图片",
            justUploadVideo: "只能上传mp4格式视频",
            pictureSize: "图片大小不能超过2M",
            videoSize: "视频大小不能大于50M",
            select_file: "选择文件",
            jobSize: "文件大小不能大于10M",
            justUploadjob: "只能上传doc,docx 或 pdf",
            filenametoolong: "文件名过长",
            DeleteThisJob: "是否删除这个文件?",
            JobUploaded: "文件已上传",
            uploading: "正在上传文件...",
            timeout: "上传超时，请重新上传",
            networkError: "网络错误，请重试",
            weeks: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
            weekstr: '周天',
            notfilledIn: "还有学生未填写状态",
            confirm: "确认",
            evaluateStudents: "评价学生",
            uploadpdforword: "上传PDF或Word文件",
            DeleteVideo: "删除视频",
            isDeleteVideo: "是否确认删除该视频",
            // 点名系统
            route_home: '考勤&点评',
            route_course: '课程',
            route_my: '我的',
            route_accountInformation: '账号信息',
            route_coursecheckin: '课程签到',
            route_comment: '课程点评',
            route_misslesson: '补课',
            status_course: '课程',
            status_SignInReview: '待签到/待点评',
            status_reviewed: '已点评',
            type_All: "全部",
            type_makeup: "未签到",
            type_review: "待点评",
            type_reviewed: "已点评",
            typecourse_day: "今日课程",
            typecourse_week: "本周课程",
            typecourse_month: "本月课程",
            tab_personreview: "个人点评",
            tab_class: "班级点评",
            tab_Homework: "上传作业",
            formLabel_courseProgress: "课程进度",
            formLabel_student: "上课人数",
            formLabel_time: "时间",
            formLabel_place: "地点",
            btn_review: "课程点评",
            btn_reviewed: "已点评",
            btn_signIn: "签到",
            btn_complementSigned: "补签",
            btn_changeDate: "更改日期",
            btn_looksignIn: "查看签到",
            accountInformation: '账号信息',
            loginOut: "退出登录",
            Available: '余',
            loading: "加载中...",
            search: "查询",
            search_placeholder: "输入学生姓名/学号",
            no_student: "查无此学生~",
            confirm_misslesson: '确定提交补课学生吗？',
            confirm_comments: '确定提交点评吗？',
            confirm_work: '确定提交布置作业吗?',
            confirm_checkin: '确认提交签到吗？',
            placeholder_student: "1、课程内容（分点论述）\n2、学生总体表",
            placeholder_class: "1、课程内容（分点论述）\n2、学生总体表",
            placeholder_work: "填写作业内容...",
            submit_type_success: "成功",
            submit_type_fail: "失败",
            addstudent: "请添加学生",
            pleaseselect: "请选择",
            cn_name: "中文名",
            en_name: "英文名",
            pullingtext: "下拉刷新...",
            loosingtext: "释放即可刷新...",
            save:"保存"

        };
    }
}