const Config = {
    imgUrl: 'https://media2.isagzth.com/',
    resAddr: "https://media.isagzth.com/isa-weapp/teacher/",
    backendUrl:"https://academy.isagzth.com/api/v1",  //正式环境
    // backendUrl: "https://test.isagzth.com/api/v1/", //测试环境
    // backendUrl: "http://172.16.32.84:8000/api/v1/", //开发环境
    uploadUrl: "https://upload.isagzth.com/upload/",
    apikey: "jn8009%343$q1",
    defaultMainImg: "https://media.isagzth.com/isa-weapp/weixin/defaultMain.jpg",
}

export default Config;